import React, {JSX, Suspense, lazy} from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import "./app.css";
import {DotLoading} from "antd-mobile";

// 懒加载组件
const Home = lazy(() => import('./pages/home/home'));
const Me = lazy(() => import('./pages/user/me'));
const Login = lazy(() => import('./pages/login/login'));
const Category = lazy(() => import('./pages/category'));
const Chapter = lazy(() => import('./pages/book/chapter'));
const Directory = lazy(() => import('./pages/book/directory'));
const Info = lazy(() => import('./pages/book/info'));
const Ranking = lazy(() => import('./pages/list/ranking'));
const List = lazy(() => import('./pages/list/list'));
const Collect = lazy(() => import('./pages/user/collect'));
const RankList = lazy(() => import('./pages/list/rank_list'));
const ErrPage = lazy(() => import('./pages/err/500'));
const Tag = lazy(() => import('./pages/tag/tag'))

function App() {
    interface iRoute {
        path: string,
        element?: JSX.Element,
        name?: string
    }

    const routes: iRoute[] = [
        {
            path: "/",
            element: <Home/>,
            name: "home"
        },
        {
            path: "/category",
            element: <Category/>,
        },
        {
            path: "/me",
            element: <Me/>,
            name: "me"
        },
        {
            path: "/collect",
            element: <Collect/>,
        },
        {
            path: "/login",
            element: <Login/>,
        },
        {
            path: "/directory/:book_id/:source_id",
            element: <Directory/>
        },
        {
            path: "/book/:book_id/:source_id/:index",
            element: <Chapter/>
        },
        {
            path: "/book/:book_id",
            element: <Info/>
        },
        {
            path: "/ranking",
            element: <Ranking/>
        },
        {
            path: "/list/:type",
            element: <List/>
        },
        {
            path: "/rank_list/:type",
            element: <RankList/>
        },
        {
            path: "/500",
            element: <ErrPage/>
        },
        {
            path: "/tag",
            element: <Tag />
        }
    ];

    return (
        <div className="App">
            <Suspense fallback={
                <p style={{fontSize: 24, textAlign: "center", marginTop: "50vh"}}>
                    <DotLoading/>
                </p>
            }>
                <Routes>
                    <Route element={<Layout/>}>
                        {
                            routes.map((route) => (
                                <Route key={route.path} path={route.path} element={route.element}>{route.name}</Route>
                            ))
                        }
                    </Route>
                </Routes>
            </Suspense>
        </div>
    );
}

export default App;

function Layout() {
    return (
        <div>
            <Outlet/>
        </div>
    )
}
